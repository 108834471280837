import {createActions} from 'redux-actions'
import {normalize} from 'normalizr'
import schema from './schema'

export const {fetch, create, update, remove, fetchTemplates,  getComments, postComment} = createActions({
    FETCH: {
      REQUESTED: undefined,
      FAILED: undefined,
      SUCCEEDED: (data) => normalize(data, [schema]),
    },
    CREATE: {
      REQUESTED: [
        ({values}) => values,
        ({resolve, reject}) => ({resolve, reject}),
      ],
      FAILED: undefined,
      SUCCEEDED: (data) => normalize(data, schema),
    },
    UPDATE: {
      REQUESTED: [
        ({values}) => values,
        ({resolve, reject}) => ({resolve, reject}),
      ],
      FAILED: undefined,
      SUCCEEDED: (data) => normalize(data, schema),
    },
    REMOVE: {
      REQUESTED: undefined,
      FAILED: undefined,
      SUCCEEDED: undefined,
    },
    GET_COMMENTS: {
      REQUESTED: [
        ({userId, messageId}) => ({userId, messageId}),
        ({resolve, reject}) => ({resolve, reject}),
      ],
      FAILED: undefined,
      SUCCEEDED: ({response, messageId}) => ({response, messageId}),
    },
    POST_COMMENT: {
      REQUESTED: [
        ({userId, messageId, comment}) => ({userId, messageId, comment}),
        ({resolve, reject}) => ({resolve, reject}),
      ],
      FAILED: undefined,
      SUCCEEDED: undefined,
    },
    FETCH_TEMPLATES: {
      REQUESTED: undefined,
      FAILED: undefined,
      SUCCEEDED: ({response}) => ({response}),
    },
  },
  {prefix: 'messages'}
)
