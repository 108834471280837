import React, { useState, useEffect } from 'react';
import {Page, Button, Box, Flex} from 'components'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {compose, branch, renderNothing} from 'recompose'
import {getActivityTypeIconEntities } from 'activities/selectors'
import {fetchActivityTypeIcons} from 'activities/actions'
import getAppUrls from 'jsonFetches/getAppUrls'

const IconSelectorPopup = ({activityTypeIcons, fetchActivityTypeIcons, onSelectedIcon, currentActivityType}) => {
  const [selectedBase64, setSelectedBase64] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reload, setReload] = useState(false);
  let baseEndpoint = getAppUrls().apiMyOrgUrl.toString().toLowerCase()
  baseEndpoint = baseEndpoint.replace(/^(https?:\/\/[^\/]+)(\/.*)?$/i, '$1');

  useEffect(() => {
      if (currentActivityType.iconUrl) {
        setSelectedUrl(baseEndpoint + "/" + currentActivityType.iconUrl) 
        setSelectedFileName(baseEndpoint + "/" + currentActivityType.iconUrl)
      }

      new Promise((resolve, reject) => {
        fetchActivityTypeIcons({
          values: {},
          resolve,
          reject
        })
      }).then(() => {
        setReload(true)
      }).catch(error => {
        console.log(error)
      });

  }, [currentActivityType]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleIconClick = (icon) => {
    setSelectedBase64(icon.base64);
    setSelectedFileName(icon.fileName);
    setSelectedUrl(icon.url)

    onSelectedIcon(icon);

    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  let imageGeneratorEndpoint = baseEndpoint + "/image/ChangeColor?imageUrl=" + encodeURIComponent(selectedUrl) + "&color=#000000"

  return (
    <div style={{ marginTop: '5px' }}>
      {selectedFileName ?
             <img src={imageGeneratorEndpoint} alt={selectedFileName} onClick={handleOpenModal}
             style={{ width: '30px', cursor: 'pointer', height: '30px', objectFit: 'contain' }}
         />
      : 
        <Button primary tiny onClick={handleOpenModal}>
            Vælg ikon
        </Button>
      }

      {isModalOpen && (
        <div 
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1000'
          }}
        >
          <div 
            style={{
              background: '#fff',
              padding: '20px',
              borderRadius: '8px',
              maxWidth: '600px',
              width: '90%'
            }}
          >
            <h2>Vælg et ikon</h2>
            <div 
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px',
                maxHeight: '300px',
                overflowY: 'auto'
              }}
            >
              {activityTypeIcons.map((icon) => (
                <div 
                  key={icon.fileName} 
                  onClick={() => handleIconClick(icon)} 
                  style={{ 
                    cursor: 'pointer',
                    padding: '5px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    background: '#000'
                  }}
                >
                  <img 
                    src={icon.url} 
                    alt={icon.fileName} 
                    style={{ width: '50px', height: '50px', objectFit: 'contain' }}
                  />
                </div>
              ))}
            </div>
            <div style={{ textAlign: 'right', marginTop: '20px' }}>
              <Button primary onClick={handleCloseModal}>Close</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const enhancer = compose(
    connect(
    createStructuredSelector({
        activityTypeIcons: getActivityTypeIconEntities
    }),
    {
        fetchActivityTypeIcons: fetchActivityTypeIcons.requested
    })
);

export default enhancer(IconSelectorPopup)
