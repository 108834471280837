import React from 'react'
import { Modal, Button, Flex, H2 } from 'components'
import { useTranslation } from 'react-i18next'

const ActivityModalWarning = ({hide, types, fetchActivityTypes, deleteActivityType, club, id}) => {
    const [t] = useTranslation()

    function deleteActivity(){
        const itemToDelete = types.filter((item) => item.clubActivityTypeId == id)[0];

        new Promise((resolve, reject) => {
          deleteActivityType({
            values: {
              clubActivityTypeId: itemToDelete.clubActivityTypeId
            },
            resolve,
            reject
          })
        }).then(() => {
          new Promise((resolve, reject) => {
            fetchActivityTypes({
              values: {clubId: club.id},
              resolve,
              reject
            })
          }).catch(error => {
            console.log(error)
          });

          hide()
        }).catch(error => {
          console.log(error)
        });
    }
  return (
    <Modal title={t('Bekræft')} hide={hide}>
        <Flex p={3} flexDirection="column">
            <H2 style={{marginBottom: "40px"}}>
                {t("Hvis du sletter denne aktivitet kan det ikke laves om")}
            </H2>
            <Button danger onClick={()=>{deleteActivity()}}>{t("Slet")}</Button>
        </Flex>
    </Modal>
  )
}

export default ActivityModalWarning