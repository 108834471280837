import React from 'react'
import {Flex, Box} from '@rebass/grid'
import {compose, mapProps} from 'recompose'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {withToggle, withRouterParams} from 'lib/hoc'
import {Button} from 'components'
import TypeList from './TypeList'
import {getActivityTypeEntities} from 'activities/selectors'
import useCustomTranslation from 'lib/customT'
import TransWrapper from 'lib/transWrapper'

const FilterDropdown = ({listVisible, toggleList, hideList, current}) => {
  const t = useCustomTranslation()

  // IMPORTANT INFO: search for "getFilteredActivities" in "selectors.js" if you look for the filter functionality

  return (
    <Flex flexDirection="column">
      <Button secondary small onClick={toggleList}>
        {t('Filtrer')} ({current ? t(`${current.name}`) : t('Alle')})
      </Button>
      <Box style={{position: 'relative'}}>
        {listVisible && <TypeList hide={hideList} />}
      </Box>
    </Flex>
  )
}

const enhancer = compose(
  // First, inject route params
  withRouterParams,
  // Next, inject Redux state
  connect(
    createStructuredSelector({
      activityTypes: getActivityTypeEntities,
    })
  ),
  // Now, map combined props to a new shape
  mapProps(({ params: { type }, activityTypes }) => ({
    current: activityTypes[type],
  })),
  // Finally, add toggle behavior
  withToggle('list')
)

export default enhancer(FilterDropdown)

