import React, {useEffect, useRef, useCallback} from 'react'
import styled from 'styled-components'
import {withRouter} from 'react-router-dom'
import qs from 'qs'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {noop} from 'lodash'
import {withProps, compose} from 'recompose'
import {Flex} from 'components'
import {withRouterParams} from 'lib/hoc'
import Item from './Item'
import {getActivityTypeEntities} from 'activities/selectors'
import useCustomTranslation from 'lib/customT'
import TransWrapper from 'lib/transWrapper'
import RemoteIcon from 'components/RemoteIcon'

const Container = styled(Flex).attrs({
  flexDirection: 'column',
  width: 200,
})`
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.5);
`

const TypeList = ({types, match: {url}, params: {archived}, hide}) => {
  const t = useCustomTranslation()
  const container = useRef()
  const outsideClickHandler = useCallback(
    (event) => {
      if (container.current && container.current.contains(event.target)) {
        return
      }
      hide()
    },
    [container, hide]
  )
  useEffect(() => {
    document.addEventListener('click', outsideClickHandler)

    return () => {
      document.removeEventListener('click', outsideClickHandler)
    }
  }, [outsideClickHandler, types])

  return (
    <div ref={container}>
      <Container>
        <Item
          to={{pathname: url, search: qs.stringify({archived})}}
          name={t('Alle')}
          color="secondary"
          renderIcon={noop}
          onClick={hide}
        />
        {types.map(({clubActivityTypeId, title, color, icon: Icon}) => {
          let activityType = null;
          let backgroundHexColorLightTheme = '#ccc';
          let fontHexColorLightTheme = '#fff'
          let iconUrl = '';
         
          activityType = types.filter(t => t.clubActivityTypeId == clubActivityTypeId)[0];
          if (activityType != null && 'backgroundHexColorLightTheme' in activityType) {
            backgroundHexColorLightTheme = activityType.backgroundHexColorLightTheme;
          }
          if (activityType != null && 'fontHexColorLightTheme' in activityType) {
            fontHexColorLightTheme = activityType.fontHexColorLightTheme;
          }

          if (activityType != null && 'iconUrl' in activityType) {
            iconUrl = activityType.iconUrl;
          }

          return activityType != null ? (
          <Item
            key={clubActivityTypeId}
            url={url}
            name={`(${t(title)})`}
            fontColor={fontHexColorLightTheme}
            color={backgroundHexColorLightTheme}
            to={{
              pathname: url,
              search: qs.stringify({archived, type: clubActivityTypeId}),
            }}
            onClick={hide}
            renderIcon={() => <RemoteIcon src={iconUrl} />}
          />
        ) : <></>})}
      </Container>
    </div>
  )
}

const enhancer = compose(
  withRouter,                // Provides routing props
  withRouterParams,          // Extracts params from the router props
  connect(
    createStructuredSelector({
      activityTypes: getActivityTypeEntities,
    })
  ),
  // Now that we have activityTypes from connect, we can filter them
  withProps(({ activityTypes }) => ({
    types: Object.values(activityTypes).filter(type => !type.hidden)
  }))
)

export default enhancer(TypeList)