import React, {useState, useEffect, useCallback} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {compose} from 'recompose'
import {withAuthenticationRequirement} from 'lib/hoc'
import {useTranslation} from 'react-i18next'
import {Modal, Button, Flex, Box, Input} from 'components'
import {createActivityType, updateActivityType, fetchActivityTypes, deleteActivityType} from 'activities/actions'
import {getActivityTypeEntities} from 'activities/selectors'
import {getActive} from 'clubs/selectors'
import {ReactComponent as Edit} from "../../../images/edit-icon.svg"
import {ReactComponent as Delete} from "../../../images/delete-icon.svg"
import styled from 'styled-components'
import ActivityModalWarning from './ActivityModalWarning'
import ColorPicker from './ColorPicker'
import IconSelectorPopup from './IconSelector'

const ModalFlex = styled(Flex)`
    gap: 25px;
    svg{
        &:hover{
            cursor: pointer;
        }
    }
`
const AddActivity = styled(Input)`
    margin-top: 20px;
`

const Label = styled.label`
  display: block;
  position: relative;
  height: 30px;
  line-height: 30px;
  margin-right: 10px;
  user-select: none;
`

const ActivityInput = styled.input`
    background: ${props => props.backgroundColor};
    border: none;
    color: ${props => props.fontColor};
    text-align: center;
    width: 100%;
    &:focus{
        outline: none;
    }
`

const ActivityModal = ({hide, create, update, fetchActivityTypes, remove, activityTypes, club}) => {
    const [editable, setEditable] = useState(false)
    const [warningModalVisible, setWarningModalVisible] = useState(false)
    const [t] = useTranslation()
    const [inputValue, setInputValue] = useState("")
    const [newActivityTypeName, setNewActivityTypeName] = useState("")

    const [renameValue, setRenameValue] = useState("")
    const [deleteId, setDeleteId] = useState("")
    const [selectedOption, setSelectedOption] = useState('')
    const [reload, setReload] = useState(false)

    const showWarningModal = useCallback(() => {
      setWarningModalVisible(true)
    }, [])
    
    const hideWarningModal = () => {
      setWarningModalVisible(false)
    }

    useEffect(()=>{
      new Promise((resolve, reject) => {
        fetchActivityTypes({
          values: {clubId: club.id},
          resolve,
          reject
        })
      }).then(() => {
        setReload(true)
      }).catch(error => {
        console.log(error)
      });
    }, [club, reload, inputValue, selectedOption, fetchActivityTypes]);



    // This handles the color changes for newEntry (the new activity type being created)
    const handleSelectedIcon = (icon, index) => { 

      new Promise((resolve, reject) => {
        update({
          values: {
            base64IconImage: icon.base64,
            clubId: club.id,
            clubActivityTypeId: activityTypes[index].clubActivityTypeId
          },
          resolve,
          reject
        })
      })
      .then(() => {
        setInputValue("1")
        setInputValue("")
      }).catch(error => {
        console.log(error)
      });
    }

    // This handles the color changes for newEntry (the new activity type being created)
    const handleColorChangeNew = (color, colorProp, index) => { 

      new Promise((resolve, reject) => {
        update({
          values: {
            [colorProp]: color,
            clubId: club.id,
            clubActivityTypeId: activityTypes[index].clubActivityTypeId
          },
          resolve,
          reject
        })
      })
      .then(() => {
        setInputValue("1")
        setInputValue("")
      }).catch(error => {
        console.log(error)
      });
    }

    return (
    <Modal title={t('Aktivitetstyper')} hide={hide}>
        <Flex p={3} flexDirection="column">

        {/* Existing activity types listing */}
        {activityTypes.length > 0 && (
          <>
            {activityTypes.map((activityType, index, array) => {
              let backgroundHexColor = activityType.backgroundHexColorLightTheme ? activityType.backgroundHexColorLightTheme : "#CCCCCC";
              let color = activityType.fontHexColorLightTheme ? activityType.fontHexColorLightTheme : "#000000";

              return (
                <Box key={activityType.clubActivityTypeId} mb={index === array.length - 1 ? 0 : 2}>
                    <ModalFlex gap="2px" style={{justifyContent: "space-between"}}>
                        <Button 
                          small 
                          block 
                          style={{
                            maxWidth:"60%", 
                            pointerEvents: "none", 
                            backgroundColor: backgroundHexColor, 
                            color: color
                          }}
                        >
                          {editable === index ? 
                            <ActivityInput 
                              value={renameValue} 
                              fontColor={color} 
                              backgroundColor={backgroundHexColor} 
                              autoFocus 
                              onChange={(e)=>{setRenameValue(e.target.value)}} 
                              onKeyDown={(e)=>{
                                if(e.key === "Enter"){
    
                                  new Promise((resolve, reject) => {
                                    update({
                                      values: {
                                        activityTypeTitle: renameValue,
                                        clubId: club.id,
                                        clubActivityTypeId: activityTypes[index].clubActivityTypeId
                                      },
                                      resolve,
                                      reject
                                    })
                                  })
                                  .then(() => {
                                    setInputValue("1")
                                    setInputValue("")
                                  }).catch(error => {
                                    console.log(error)
                                  });

                                  setEditable("")
                                }
                              }}
                            /> : 
                            t(activityType.title)
                          }
                        </Button>

                        <IconSelectorPopup currentActivityType={activityType} onSelectedIcon={(icon) => handleSelectedIcon(icon, index)} />
                        
                        <Delete onClick={()=>{
                            setDeleteId(activityType.clubActivityTypeId)
                            showWarningModal() 
                        }}/>

                        <Edit onClick={()=>{
                            if (editable !== index){
                                setRenameValue(t(`${activityType.title}`))
                                setEditable(index)
                            } else {
                                activityTypes[index].title = renameValue
                                setEditable("")
                            }
                        }}/>

                    </ModalFlex>
                    <div style={{ paddingTop: '10px', borderBottom: '1px solid #ccc', paddingBottom: '40px', }}>
                      <div style={{ paddingBottom: '10px', }}>
                          <Flex>
                            <Label style={{ width: '170px'}}>Skrift farve neatral</Label>
                            <ColorPicker
                              key={1}
                              color={activityTypes[index].fontHexColorLightTheme || ''}
                              handleColorChange={(color) => handleColorChangeNew(color, 'FontHexColor_LightTheme', index)}
                              index={1}
                            />

                            <Label style={{ paddingLeft: '20px', width: '170px'}}>Skrift farve lys</Label>
                            <ColorPicker
                              key={1}
                              color={activityTypes[index].fontHexColorDarkLightTheme || ''}
                              handleColorChange={(color) => handleColorChangeNew(color, 'FontHexColorDark_LightTheme', index)}
                              index={1}
                            />

                            <Label style={{ paddingLeft: '20px', width: '170px'}}>Skrift farve mørk</Label>
                            <ColorPicker
                              key={1}
                              color={activityTypes[index].fontHexColorLightLightTheme || ''}
                              handleColorChange={(color) => handleColorChangeNew(color, 'FontHexColorLight_LightTheme', index)}
                              index={1}
                            />
                          </Flex>
                      </div>
                      <div>
                        <Flex>
                            <Label style={{ width: '170px'}}>Baggrundsfarve neatral</Label>
                            <ColorPicker
                              key={1}
                              color={activityTypes[index].backgroundHexColorLightTheme || ''}
                              handleColorChange={(color) => handleColorChangeNew(color, 'BackgroundHexColor_LightTheme', index)}
                              index={1}
                            />

                            <Label style={{ paddingLeft: '20px', width: '170px'}}>Baggrundsfarve lys</Label>
                            <ColorPicker
                              key={1}
                              color={activityTypes[index].backgroundHexColorLightLightTheme || ''}
                              handleColorChange={(color) => handleColorChangeNew(color, 'BackgroundHexColorLight_LightTheme', index)}
                              index={1}
                            />
                            
                            <Label style={{ paddingLeft: '20px', width: '170px'}}>Baggrundsfarve mørk</Label>
                            <ColorPicker
                              key={1}
                              color={activityTypes[index].backgroundHexColorDarkLightTheme || ''}
                              handleColorChange={(color) => handleColorChangeNew(color, 'BackgroundHexColorDark_LightTheme', index)}
                              index={1}
                            />
                        </Flex>
                      </div>
                    </div>

                </Box>
              )
            })}
          </>
        )}

        {warningModalVisible && 
          <ActivityModalWarning
          hide={hideWarningModal}
            club={club} types={activityTypes}
            fetchActivityTypes={fetchActivityTypes}
            deleteActivityType={remove}
            id={deleteId}/>
        }

        {/* Input for creating a new activity type */}
        <AddActivity 
          type="text" 
          placeholder={t("Tilføj Aktivitetstype")} 
          value={newActivityTypeName}
          onChange={(e)=>{
            setNewActivityTypeName(e.target.value)
          }}
          onKeyDown={(e)=>{
            if(e.key === "Enter" && /[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(inputValue))
            {
              new Promise((resolve, reject) => {
                create({
                  values: {
                  activityTypeTitle: newActivityTypeName,
                  clubId: club.id
                }, resolve, reject})
              }).then(() => {
                setInputValue("1")
                setInputValue("")
                setNewActivityTypeName("")
              }).catch(error => {
                console.log(error)
              });
            }
          }}
        />

        {
          newActivityTypeName === "" ? 
          <Button disabled>
            {t("Tilføj")}
          </Button>
          : 
          <Button onClick={()=>{
            new Promise((resolve, reject) => {
              create({
                values: {
                  activityTypeTitle: newActivityTypeName,
                  clubId: club.id
                }, resolve, reject})
            }).then(() => {
              setInputValue("1")
              setInputValue("")
              setNewActivityTypeName("")
            }).catch(error => {
              console.log(error)
            });
          }}>
            {t("Tilføj")}
          </Button>
        }
    </Flex>
    </Modal>
  )
}


const enhancer = compose(
  withAuthenticationRequirement,
  connect(
    createStructuredSelector({
      club: getActive,
      activityTypes: getActivityTypeEntities
    }),
    {
      create: createActivityType.requested,
      fetchActivityTypes: fetchActivityTypes.requested,
      remove: deleteActivityType.requested,
      update: updateActivityType.requested
    }
  )
)

export default enhancer(ActivityModal)