import React from 'react'
import { useEffect } from 'react'
import {Field} from 'formik'
import { connect } from 'react-redux'
import {FormikInput as Input, TextArea, Text, Flex, Switch, Button, Dropdown, Box, Tooltip} from 'components'
import useCustomTranslation from 'lib/customT'
import TransWrapper from 'lib/transWrapper'
import { useFeature } from "@growthbook/growthbook-react";
import { module_activity_toggle_participant_status, module_message_template } from 'globalModuleNames';
import { fetchTemplates } from 'messages/actions'
import { getTemplates } from 'messages/selectors'


const Details = ({setFieldValue, fetchTemplates, templates}) => {
  const t = useCustomTranslation()

  const insertTemplate = (message, title)=> {
    setFieldValue("message", message)
    setFieldValue("title", title)
  }

  useEffect(()=> {
    fetchTemplates()
  }, [])

  return (
    <>
      <Field
        small
        name="title"
        placeholder={`${t('Titel')} *`}
        component={Input}
      />
      <Field name="message">
        {({field, form, meta: {error, touched}}) => (
          <>
            <TextArea
              last
              small
              rows={10}
              placeholder={`${t('Besked')} *`}
              {...field}
            />
            {touched && error && <Text danger>{error}</Text>}
          </>
        )}
      </Field>
        
      {useFeature(module_message_template).on &&
          <Dropdown 
              title={t("Indsæt skabelon")}
              items={templates}
              renderItem={({message, title}, hide)=>{ return <Button onClick={()=>{ 
                 insertTemplate(message, title)
                 hide()
              }} width={"100%"} type='button' title={message} small>{title} </Button>}}/>
      }


      <Flex justifyContent="space-between" my={3}>
        <Text secondary>{t('Send til alle medlemmer')}</Text>

        <Field small name="allMembers">
          {({field: {name, value}, form: {setFieldValue}}) => (
            <Switch
              value={value}
              onChange={() => setFieldValue(name, !value)}
            />
          )}
        </Field>
      </Flex>
    </>
  )
}

const enhancer = connect(
  (state) => ({
    templates: getTemplates(state)
  }),
  { fetchTemplates: fetchTemplates.requested }
);

export default enhancer(Details);