import React, {useState, useEffect, useContext, useCallback} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {withAuthenticationRequirement} from 'lib/hoc'
import {fetchActivityTypes} from 'activities/actions'
import {getActivityTypeEntities} from 'activities/selectors'
import {getActive} from 'clubs/selectors'
import styled from 'styled-components'
import { darken } from 'polished'
import { withProps, compose, pure } from 'recompose'
import { Flex, Box } from '@rebass/grid'
import { Row, Cell } from 'components/Table'
import { Clock } from 'components/icons'
import { typesById } from 'activities/constants'
import format from 'lib/format'
import Context from '../Context'
import RemoteIcon from 'components/RemoteIcon'

const Container = styled(Row).attrs({
  alignItems: 'center',
})`
  background: ${props => props.customColor};
  cursor: pointer;

  will-change: background;
  transition: background 0.125s ease;

  &:hover {
    background: ${props => darken(0.1, props.customColor)};
  }
`

const renderIconForType = (iconUrl, fontHexColorLightTheme) => <RemoteIcon src={iconUrl} color={fontHexColorLightTheme} />

const ActivityTableRow = ({id, start, end, title, type, club, fetchActivityTypes, activityTypes, ...rest}) => {
  const {showShowModal} = useContext(Context)

  let activityType = null;
  let backgroundHexColorLightTheme = '#ccc';
  let fontHexColorLightTheme = '#fff';
  let iconUrl = '';

  if (activityTypes.length > 0) {
    activityType = activityTypes.filter(t => t.clubActivityTypeId == type.clubActivityTypeId)[0];
    if (activityType != null && 'backgroundHexColorLightTheme' in activityType) {
      backgroundHexColorLightTheme = activityType.backgroundHexColorLightTheme;
    }
    if (activityType != null && 'fontHexColorLightTheme' in activityType) {
      fontHexColorLightTheme = activityType.fontHexColorLightTheme;
    }
    if (activityType != null && 'iconUrl' in activityType) {
      iconUrl = activityType.iconUrl;
    }
  }

  return (
    <Container customColor={backgroundHexColorLightTheme} onClick={() => showShowModal(id)} {...rest}>
      <Cell light color={fontHexColorLightTheme} width={115}>
        {format(start, 'ddd D[.] MMM')}
      </Cell>
      <Cell>{renderIconForType(iconUrl, fontHexColorLightTheme)}</Cell>
      <Cell color={fontHexColorLightTheme} flex="1">
        {title}
      </Cell>
      <Cell light color={fontHexColorLightTheme}>
        <Flex alignItems="center">
          <Box mr={2}>
            <Clock size={16} fill="white" />
          </Box>
          <Box>
            {format(start, 'HH:mm')} {end && `- ${format(end, 'HH:mm')}`}
          </Box>
        </Flex>
      </Cell>
    </Container>)
}

const enhancer = compose(
  withAuthenticationRequirement,
  connect(
    createStructuredSelector({
      club: getActive,
      activityTypes: getActivityTypeEntities
    }),
    {
      fetchActivityTypes: fetchActivityTypes.requested,
    }
  )
)

export default enhancer(ActivityTableRow)