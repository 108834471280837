import React, {useState, useCallback} from 'react'
import {compose} from 'recompose'
import {createStructuredSelector} from 'reselect'
import {withAuthenticationRequirement} from 'lib/hoc'
import {connect} from 'react-redux'
import {getActive} from 'clubs/selectors'
import {getActivityTypeEntities} from 'activities/selectors'
import { useTranslation } from 'react-i18next'
import { Text, Button, Modal } from 'components'
import styled from 'styled-components'
import ActivityModal from './ActivityModal'


const ActivityButton = styled(Button)`
    margin-top: 30px;
`

const Activities = ({activityTypes}) => {
    const [t] = useTranslation()
    const [activityModalVisible, setActivityModalVisible] = useState(false)

  const showActivityModal = useCallback(() => {
    
    setActivityModalVisible(true)
  }, [])


  const hideActivityModal = () => {
    setActivityModalVisible(false)
  }
  return (
    <>
       <Text>
            {t('Her kan du se et overblik over de standard aktivitetstyper, redigere i deres titler og tilføje aktivitetstyper.')}
        </Text>
        <ActivityButton onClick={()=>{
            showActivityModal()
        }}>{t("Opsæt aktivitetstyper")}</ActivityButton>

        {/* <ActivityButton style={{display: "block"}}>{t("Aktivitets Skabeloner")}</ActivityButton> */}

        {activityModalVisible && (
            <ActivityModal
            hide={hideActivityModal}
            />
        )}
    </>


  )
}

const enhancer = compose(
  withAuthenticationRequirement,
  connect(
    createStructuredSelector({
      club: getActive,
    })
  )
)

export default enhancer(Activities)