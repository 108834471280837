import React from 'react';
import getAppUrls from 'jsonFetches/getAppUrls'
// Import or define getAppUrls() in your code. This is just a placeholder:
// import { getAppUrls } from 'your-utils-file';

const RemoteIcon = ({ src, width = 25, height = 25, alt = 'icon', color = '#FFFFFF' }) => {
  // Get and normalize the base endpoint
  let baseEndpoint = getAppUrls().apiMyOrgUrl.toString().toLowerCase();
  baseEndpoint = baseEndpoint.replace(/^(https?:\/\/[^\/]+)(\/.*)?$/i, '$1');

  // If the remoteIconUrl does not start with 'http', treat it as relative and prefix it
  let iconSrc = `${baseEndpoint}/${src}`;

  let imageGeneratorEndpoint = baseEndpoint + "/image/ChangeColor?imageUrl=" + encodeURIComponent(iconSrc) + "&color=" + encodeURIComponent(color);

  return src ? (
    <img
      src={imageGeneratorEndpoint}
      width={width}
      height={height}
      alt={alt}
      style={{ objectFit: 'contain', display: 'inline-block' }}
    />
  ) : <><span style={{ height: '25px', width: '25px'}}></span></>;
};

export default RemoteIcon;