import React, {useState, useEffect, useCallback} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {compose} from 'recompose'
import {withAuthenticationRequirement} from 'lib/hoc'
import {fetchActivityTypes} from 'activities/actions'
import {getActivityTypeEntities} from 'activities/selectors'
import {getActive} from 'clubs/selectors'
import styled from 'styled-components'
import {darken} from 'polished'
import format from 'lib/format'
import {Box, Text, Link, Flex} from 'components'
import {Clock} from 'components/icons'
import RemoteIcon from 'components/RemoteIcon'

const Container = styled(Flex).attrs({
  py: 1,
  px: 2,
  alignItems: 'center',
  justifyContent: 'space-between',
})`
  cursor: pointer;
  background: ${props => props.customColor};

  will-change: background;
  transition: background 0.125s ease;

  &:hover {
    background: ${props => darken(0.1, props.customColor)};
  }
`

const Row = ({type: typeId, title, start, id, end, club, fetchActivityTypes, activityTypes}) => {
  const [reload, setReload] = useState(false)

  useEffect(()=>{

    if (activityTypes == null || (activityTypes != null && activityTypes.length == 0)) {

      new Promise((resolve, reject) => {
        fetchActivityTypes({
          values: {clubId: club.id},
          resolve,
          reject
        })
      }).then(() => {
        setReload(true);
      }).catch(error => {
        console.log(error)
      });
    }
  }, [club, reload]);

  let activityType = null;
  let backgroundHexColorLightTheme = '#ccc';
  let iconUrl = '';
  if (activityTypes.length > 0) {
    activityType = activityTypes.filter(t => t.clubActivityTypeId == typeId.clubActivityTypeId)[0];
    if (activityType != null && 'backgroundHexColorLightTheme' in activityType) {
      backgroundHexColorLightTheme = activityType.backgroundHexColorLightTheme;
    }

    if (activityType != null && 'iconUrl' in activityType) {
      iconUrl = activityType.iconUrl;
    }
  }

  return (
    <Link to={{pathname: '/activities', state: {open: id}}}>
      <Container customColor={backgroundHexColorLightTheme}>
        <Flex alignItems="center">
          <Text light>{format(start, 'ddd D[.] MMM')}</Text>
          <Box mx={3}>
            <RemoteIcon src={iconUrl} />
          </Box>
          <Text light>{title}</Text>
        </Flex>
        <Flex alignItems="center">
          <Box mr={2}>
            <Clock size={16} fill="white" />
          </Box>
          <Text light>
            {format(start, 'HH:mm')} {end && `- ${format(end, 'HH:mm')}`}
          </Text>
        </Flex>
      </Container>
    </Link>
  )
}

const enhancer = compose(
  withAuthenticationRequirement,
  connect(
    createStructuredSelector({
      club: getActive,
      activityTypes: getActivityTypeEntities
    }),
    {
      fetchActivityTypes: fetchActivityTypes.requested,
    }
  )
)

export default enhancer(Row)
