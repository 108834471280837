import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

const ColorPickerModal = ({handleColorChange, index}) => {
  const [background, setBackground] = useState('#fff');

  const handleChangeComplete = (color) => {
    handleColorChange(color.hex, index)
    setBackground(color.hex);
  };

  return (
    <SketchPicker
      color={background}
      onChangeComplete={handleChangeComplete}
      presetColors={[
        "#86C7E3", "#dd9295", "#a094e5", "#b1e498", "#e1b487"
      ]}
      disableAlpha
    />
  );
};

export default ColorPickerModal;