import React, {useState, useEffect, useCallback} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {compose} from 'recompose'
import {withAuthenticationRequirement} from 'lib/hoc'
import {getActivityTypeEntities} from 'activities/selectors'
import {fetchActivityTypes} from 'activities/actions'
import {getActive} from 'clubs/selectors'
import useCustomTranslation from 'lib/customT'
import TransWrapper from 'lib/transWrapper'
import {Button, Flex, Box} from 'components'

const Choose = ({values, setFieldValue, nextStep, activityTypes, fetchActivityTypes, club}) => {
  const t = useCustomTranslation()
  const [reload, setReload] = useState(false)

  const setType = useCallback(
    (id) => {
      setFieldValue('type', id)
      nextStep()
    },
    [setFieldValue, nextStep]
  )

  useEffect(()=>{
    new Promise((resolve, reject) => {
      fetchActivityTypes({
        values: {clubId: club.id},
        resolve,
        reject
      })
    }).then(() => {
      setReload(true)
    }).catch(error => {
      console.log(error)
    });
  }, [club, reload, fetchActivityTypes]);


  return (
    <Flex p={3} flexDirection="column">
      {activityTypes.map((activityType, index, array) => {       
        let backgroundHexColor = activityType.backgroundHexColorLightTheme ? activityType.backgroundHexColorLightTheme : "#CCCCCC";
        let color = activityType.fontHexColorLightTheme ? activityType.fontHexColorLightTheme : "#000000";
        
        return (
        <Box key={index} mb={index === array.length - 1 ? 0 : 2}>
          <Button small color={color} block customBg={backgroundHexColor} onClick={() => setType(activityType.clubActivityTypeId)}>
            ({t(activityType.title)})
          </Button>
        </Box>
      )})}
    </Flex>
  )
}

const enhancer = compose(
  withAuthenticationRequirement,
  connect(
    createStructuredSelector({
      club: getActive,
      activityTypes: getActivityTypeEntities
    }),
    {
      fetchActivityTypes: fetchActivityTypes.requested
    }
  )
)

export default enhancer(Choose)
