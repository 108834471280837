import React, {useRef} from 'react'
import {Button, Box, Flex} from 'components'
import {useToggle} from 'lib/hooks'
import List from 'components/Dropdown/List'
import styled from 'styled-components'
import ColorPickerModal from './ColorPickerModal'

const ColorPickerDiv = styled(Button)`
  height: 30px;
  width: 30px;
  padding: 0;
  border-radius: 100%;
  background-color: ${props => props.color};
  align-self: center;
  justify-self: center;
  &:hover {
    cursor: pointer;
    background-color: ${props => props.color};
  }
`;

const Dropdown = ({
  title,
  renderButton,
  block,
  handleColorChange,
  color,
  index
}) => {
  const [isOpen, , hide, toggle] = useToggle()
  const button = useRef()

  return (
    <Box position="relative" ref={button.current}>
      {renderButton ? (
        renderButton(toggle)
      ) : (
        <ColorPickerDiv small primary block={block} onClick={toggle} color={color}>
          <Flex justifyContent="space-between" alignItems="center">
            <Box mr={2}>{typeof title === 'function' ? title() : title}</Box>
          </Flex>
        </ColorPickerDiv>
      )}
      <Box position="absolute" top={0} right={0} zIndex={1000} style={{margin: 0}}>
        {isOpen && (
          <Box p={2} boxShadow="0 0 8px rgba(0,0,0,0.15)">
            <Flex justifyContent="flex-end" mb={2} position="absolute" bottom="5px" right="15px" zIndex={1000} >
              <Button tiny onClick={hide}>Luk</Button>
            </Flex>
            <ColorPickerModal handleColorChange={handleColorChange} index={index}/>
          </Box>
        )}
      </Box>
    </Box>
  )
}

Dropdown.defaultProps = {
  hideDivider: false,
  addtionals: [],
}

export default Dropdown